/* .gallery{
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    margin-left: 10rem;

}
.gallery img{
    width: 20%;
    margin: 10px;
} */










*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	min-height: 100vh;
	background-color: #fafafa;
}

.container-10 {
	max-width: 100rem;
	margin: 0 auto;
	padding: 0 2rem 2rem;
}



.heading span {
	display: block;
}

.gallery {
	display: flex;
	flex-wrap: wrap;
	margin: -1rem -1rem;
}

.gallery-item {
	flex: 1 0 24rem;
	margin: 1rem;
	width: 88%;
	box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
	overflow: hidden;
}

.gallery-image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: transform 400ms ease-out;
}

.gallery-image:hover {
	transform: scale(1.15);
}

/*

The following rule will only run if your browser supports CSS grid.

Remove or comment-out the code block below to see how the browser will fall-back to flexbox styling. 

*/

@supports (display: grid) {
	.gallery {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
		grid-gap: 2rem;
	}

	.gallery,
	.gallery-item {
		margin: 0;
	}
}
