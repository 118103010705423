.service-container{
    margin-top: 15%;
    display: flex;

}
.service-container img{
    width: 100%;
    margin-left: 3rem;
}
.service-text{
    margin-left: 3rem;
    text-align: center;
}
.service-text p{
    margin: 3rem;
}