.bttn {
  border: 2px solid black;
  background-color: #FFB800;
  color: black;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
}
@media only screen and (max-width:425px) {
.bttn{
  padding: 3px 9px;
  font-size: 10px;
}


}