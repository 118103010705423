.topnav {
    position: fixed;
    background-color: rgb(255, 255, 255);
    width: 100%;
    z-index: 100;
    top: 0;
    border-bottom: 1px solid black;
  }
  .logo img{
    padding: 12px;
  }
  
  .topnav .links {
    float: right;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  

  }
  a{
    text-decoration: none;
    color: black;
    padding: 5px;
  }
  .topnav a:hover {
    color: #ffc107;
  }
  .topnav a:active {

    color: #ffc107;
    text-decoration: none;
  }
  
  .topnav .icon {
    display: none;
  }
  .logo{
  float: left;
  }
  
  @media screen and (max-width: 600px) {
    .topnav a:not(:first-child) {display: none;}
    .topnav a.icon {
      float: right;
      display: block;
    }
  }
  @media screen and (max-width: 425px) {
  .logo img{
    padding: 3px;
    width: 88%;
  }
  }
  
  @media screen and (max-width: 600px) {
    .topnav.responsive {position: relative;}
    .topnav.responsive .icon {
      position: absolute;
      right: 0;
      top: 0;
    }
    .topnav.responsive a {
      float: none;
      display: block;
      text-align: left;
    }
  }


  /*  */
  /*  */
  /*  */
  /*  */

  ul {     
    padding:0;     
    margin:0    
}    
/* a {    
    display:block;    
    text-decoration: none;    
}     */
li {    
    display:block;     
    float:left;    
}    
li ul li {    
    float:none;    
}    
li ul {    
    display:none;     
    position:absolute;     
    z-index:10;    
}    
li:hover ul {    
    display:block;    
}    
    
/*DROPDOWN STYLING:*/    
#menu {    
    height:30px    
}    
  
#menu a:hover {    
    color:#ffffff;    
}    
#menu li {    
    background-color: #FFF;
    /* border: solid 1px #CCC; */
    margin-right: 38px;
    text-align: left;
}    
#menu li:hover {    
    background-color:#0072BA    
}